.container{
	max-width: 1231px;
	margin: auto;
	padding-left: 15px;
	padding-right: 15px;
}
@media (max-width: 1299.5px){
	.container{
		max-width: 1170px;
	}
}
@media (max-width: 1199.5px){
	.container{
		max-width: 962px;
	}
}
@media (max-width: 991.5px){
	.container{
		max-width: none;
	}
}
@media (max-width: 575.5px){
	.container{
		// padding-left: 0;
		// padding-right: 0;
	}
}