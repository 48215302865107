@import "_defs/vars";
@import "_mixins/breakpoints";
@import "_defs/norset";
@import "_defs/fonts";
@import "_defs/libs-first";

html{
	font-size: 10px;
}
body {
	font-size: 16px;
	position: relative;
	font-family: $default-font;
	color: $color;
}
#site{
	overflow: hidden;
	position: relative;
	background: $color-white;
}
picture{
	font-size: 0;
	display: inline-block;
}
input, textarea{
	font-family: $default-font;
}
main{
	position: relative;
	z-index: 1;
}
@import "_defs/common_elements";
@import "_defs/container";
@import "_defs/buttons";
@import "_blocks/header";
// ============================
@import	"_blocks/banner";