* {
  margin: 0;
  outline: none;
  padding: 0;
  box-sizing: border-box;
}

*:after, *:before {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

::placeholder {
  color: #666;
}

::selection {
  background-color: #3390FF;
  color: #fff;
  text-shadow: none;
}

b, strong {
  font-weight: bold;
}

ol, ul {
  list-style: none;
}

input, textarea {
  border: #666 1px solid;
  outline: none;
}

input:focus:required:invalid, textarea:focus:required:invalid {
  border-color: red;
}

input:required:valid, textarea:required:valid {
  border-color: green;
}

body {
  line-height: 1.15;
  min-width: 320px;
  width: 100%;
  height: 100%;
}

[hidden] {
  display: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

@font-face {
  font-family: "BebasNeue";
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/BebasNeueLight.woff") format("woff");
}

@font-face {
  font-family: "ProximaNova";
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/ProximaNova-Black.woff") format("woff");
}

@font-face {
  font-family: "ProximaNova";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/ProximaNova-Bold.woff") format("woff");
}

@font-face {
  font-family: "ProximaNova";
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/ProximaNova-Extrabld.woff") format("woff");
}

@font-face {
  font-family: "ProximaNova";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/ProximaNova-Regular.woff") format("woff");
}

@font-face {
  font-family: "TTNorms";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/TTNorms-Regular.woff") format("woff");
}

html {
  font-size: 10px;
}

body {
  font-size: 16px;
  position: relative;
  font-family: "ProximaNova", sans-serif;
  color: #070707;
}

#site {
  overflow: hidden;
  position: relative;
  background: #fff;
}

picture {
  font-size: 0;
  display: inline-block;
}

input, textarea {
  font-family: "ProximaNova", sans-serif;
}

main {
  position: relative;
  z-index: 1;
}

.nobr {
  white-space: nowrap;
}

.title-main {
  color: #fffefe;
  font-size: 60px;
  font-weight: 400;
  line-height: 1;
}

@media (max-width: 1199.5px) {
  .title-main {
    font-size: 50px;
  }
}

@media (max-width: 1199.5px) {
  .title-main {
    font-size: 40px;
  }
}

@media (max-width: 767.5px) {
  .title-main {
    font-size: 31px;
  }
}

.title-main b {
  font-weight: 800;
}

.title-common {
  color: #b8274e;
  font-size: 60px;
  font-weight: 900;
  line-height: 1;
  text-transform: uppercase;
}

@media (max-width: 1199.5px) {
  .title-common {
    font-size: 50px;
  }
}

@media (max-width: 1199.5px) {
  .title-common {
    font-size: 40px;
  }
}

@media (max-width: 767.5px) {
  .title-common {
    font-size: 31px;
  }
}

.container {
  max-width: 1231px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 1299.5px) {
  .container {
    max-width: 1170px;
  }
}

@media (max-width: 1199.5px) {
  .container {
    max-width: 962px;
  }
}

@media (max-width: 991.5px) {
  .container {
    max-width: none;
  }
}

.button {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: none;
}

.button_wrap {
  text-align: center;
}

.button-common {
  color: #b7274c;
  font-size: 15px;
  font-weight: 400;
  line-height: 2.13;
  text-transform: uppercase;
  width: 157px;
  height: 45px;
  box-shadow: 3px 1px 7px 1px rgba(2, 3, 2, 0.27);
  background-color: #ffffff;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .3s, color .3s;
}

@media (max-width: 575.5px) {
  .button-common {
    font-size: 14px;
    width: 140px;
  }
}

.button-common:hover {
  transition: background-color .3s, color .3s;
  background-color: #ff7d00;
  color: #ffffff;
}

.button-buy {
  color: #ffffff;
  font-size: 22px;
  font-weight: 400;
  line-height: 2.13;
  text-transform: uppercase;
  box-shadow: 3px 1px 7px 1px rgba(2, 3, 2, 0.27);
  border-radius: 33px;
  background-color: #b7274c;
  width: 192px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .3s, color .3s;
}

@media (max-width: 991.5px) {
  .button-buy {
    width: 170px;
  }
}

.button-buy:hover {
  transition: background-color .3s, color .3s;
  background-color: #ff7d00;
  color: #ffffff;
}

.button-scroll {
  width: 30px;
  height: 21px;
  border: none;
  background: transparent;
  transition: background-image .3s;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='30.5px' height='21.5px'%3E %3Cpath fill-rule='evenodd' stroke='rgb(255, 125, 0)' stroke-width='5px' stroke-linecap='round' stroke-linejoin='round' fill='none' d='M24.801,7.723 L18.004,14.775 C15.796,17.066 12.216,17.066 10.008,14.775 L3.212,7.723 '/%3E %3C/svg%3E");
}

.button-scroll:hover {
  transition: background-image .3s;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='30.5px' height='21.5px'%3E %3Cpath fill-rule='evenodd' stroke='rgb(184, 39, 78)' stroke-width='5px' stroke-linecap='round' stroke-linejoin='round' fill='none' d='M24.801,7.723 L18.004,14.775 C15.796,17.066 12.216,17.066 10.008,14.775 L3.212,7.723 '/%3E %3C/svg%3E");
}

.header {
  background-color: #ff7d00;
  height: 75px;
}

@media (max-width: 991.5px) {
  .header {
    height: 65px;
  }
}

@media (max-width: 575.5px) {
  .header {
    height: 48px;
  }
}

.header .container {
  display: flex;
  justify-content: center;
  position: relative;
}

@media (max-width: 767.5px) {
  .header .container {
    align-items: center;
    justify-content: space-between;
  }
}

.header .container:after {
  content: '';
  display: block;
  width: 1px;
}

@media (max-width: 767.5px) {
  .header .container:after {
    content: none;
  }
}

.header .logo {
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  top: 50%;
  transform: translateY(-50%);
  margin-left: -6px;
  margin-top: -4px;
}

@media (max-width: 991.5px) {
  .header .logo {
    margin-left: 15px;
    width: 190px;
  }
  .header .logo img {
    width: 100%;
  }
}

@media (max-width: 767.5px) {
  .header .logo {
    position: static;
    margin-top: 0;
    margin-left: 0;
    transform: none;
  }
}

@media (max-width: 575.5px) {
  .header .logo {
    width: 140px;
  }
}

.header .logo .text {
  color: #ffffff;
  font-family: "TTNorms", sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 0.8;
  text-align: center;
  margin-top: 7px;
}

@media (max-width: 991.5px) {
  .header .logo .text {
    font-size: 20px;
    margin-top: 6px;
  }
}

@media (max-width: 575.5px) {
  .header .logo .text {
    font-size: 14px;
  }
}

@media (max-width: 991.5px) {
  .header .logo_dns {
    width: 250px;
  }
  .header .logo_dns img {
    width: 100%;
  }
}

@media (max-width: 575.5px) {
  .header .logo_dns {
    width: 160px;
  }
}

.banner {
  background-image: url(../img/old/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-top: 52px;
  padding-bottom: 43px;
}

@media (max-width: 1199.5px) {
  .banner {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media (max-width: 991.5px) {
  .banner {
    padding-top: 10px;
  }
}

.banner .title-main {
  text-align: center;
}

.banner .mapper_1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 23%;
  height: 80%;
}

.banner .mapper_2 {
  position: absolute;
  left: 23%;
  top: 0;
  width: 29%;
  height: 85%;
  z-index: 1;
}

.banner .mapper_3 {
  position: absolute;
  left: 50%;
  top: 22%;
  width: 31.2%;
  height: 65%;
  z-index: 2;
}

.banner .mapper_4 {
  position: absolute;
  left: 67%;
  top: 0;
  width: 32%;
  height: 65%;
}

.banner .products {
  display: flex;
  margin-top: 155px;
  width: 100%;
  position: relative;
}

@media (max-width: 1199.5px) {
  .banner .products {
    margin-top: 130px;
  }
}

@media (max-width: 991.5px) {
  .banner .products {
    margin-top: 100px;
  }
}

@media (max-width: 575.5px) {
  .banner .products {
    margin-top: 77px;
  }
}

.banner .products .image {
  width: 100%;
  position: relative;
}

.banner .products .image img {
  max-width: 100%;
}

.banner .products .name {
  color: #ffffff;
  font-size: 26px;
  font-weight: 900;
  line-height: 0.48;
  text-transform: uppercase;
}

@media (max-width: 1199.5px) {
  .banner .products .name {
    font-size: 25px;
  }
}

@media (max-width: 767.5px) {
  .banner .products .name {
    font-size: 18px;
  }
}

@media (max-width: 575.5px) {
  .banner .products .name {
    font-size: 11px;
  }
}

.banner .products .info {
  position: absolute;
  text-align: right;
}

.banner .products .info1 {
  left: 0;
  top: -97px;
}

@media (max-width: 991.5px) {
  .banner .products .info1 {
    top: auto;
    bottom: -37px;
  }
}

@media (max-width: 575.5px) {
  .banner .products .info1 {
    top: -54px;
  }
}

.banner .products .info2 {
  left: 27.5%;
  top: -97px;
}

@media (max-width: 1199.5px) {
  .banner .products .info2 {
    left: 30%;
  }
}

@media (max-width: 991.5px) {
  .banner .products .info2 {
    top: -80px;
    left: 21%;
  }
}

@media (max-width: 575.5px) {
  .banner .products .info2 {
    top: auto;
    bottom: -37px;
    left: 6%;
  }
}

.banner .products .info3 {
  display: flex;
  align-items: center;
  left: 52.2%;
  bottom: -18px;
}

@media (max-width: 575.5px) {
  .banner .products .info3 {
    flex-direction: column;
    bottom: -32px;
    left: 60%;
  }
}

.banner .products .info3 .name {
  margin-top: 18px;
}

.banner .products .info3 .price {
  margin-left: 30px;
}

@media (max-width: 575.5px) {
  .banner .products .info3 .price {
    margin-left: 15px;
  }
}

.banner .products .info4 {
  right: 2%;
  top: -97px;
}

@media (max-width: 991.5px) {
  .banner .products .info4 {
    top: -80px;
  }
}

@media (max-width: 575.5px) {
  .banner .products .info4 {
    right: 0;
    top: -60px;
  }
}

.banner .products .price {
  display: flex;
  margin-top: 15px;
  align-items: center;
}

.banner .products .price .old {
  color: #ffffff;
  line-height: 1.11;
  text-align: center;
  margin-right: 20px;
  margin-top: 9px;
  font-size: 25px;
  position: relative;
}

@media (max-width: 1199.5px) {
  .banner .products .price .old {
    font-size: 20px;
    margin-right: 10px;
  }
}

@media (max-width: 767.5px) {
  .banner .products .price .old {
    font-size: 12px;
  }
}

@media (max-width: 575.5px) {
  .banner .products .price .old {
    font-size: 10px;
  }
}

.banner .products .price .old .value {
  font-family: "ProximaNova", sans-serif;
  font-weight: 300;
  font-size: 28px;
  margin-right: 4px;
}

@media (max-width: 1199.5px) {
  .banner .products .price .old .value {
    font-size: 23px;
  }
}

@media (max-width: 767.5px) {
  .banner .products .price .old .value {
    font-size: 16px;
    margin-right: 1px;
  }
}

@media (max-width: 575.5px) {
  .banner .products .price .old .value {
    font-size: 14px;
  }
}

.banner .products .price .old:after {
  content: '';
  position: absolute;
  height: 3px;
  width: 110%;
  background: #ff7d00;
  left: -5%;
  top: 50%;
  transform: rotate(-3deg) translateY(-50%);
}

@media (max-width: 767.5px) {
  .banner .products .price .old:after {
    height: 2px;
  }
}

.banner .products .price .new {
  color: #ffffff;
  position: relative;
  text-align: center;
  padding: 1.8% 6% 2%;
  min-width: 167px;
}

@media (max-width: 1199.5px) {
  .banner .products .price .new {
    padding: 1.2% 3%;
    min-width: 150px;
  }
}

@media (max-width: 767.5px) {
  .banner .products .price .new {
    min-width: auto;
    padding: 1.2% 7%;
  }
}

.banner .products .price .new::before {
  content: '';
  background-color: #ff7d00;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  transform: skewX(-18deg);
}

.banner .products .price .new .inner {
  position: relative;
  z-index: 1;
  white-space: nowrap;
  font-weight: 300;
  font-size: 29px;
  font-family: "BebasNeue", sans-serif;
}

@media (max-width: 1199.5px) {
  .banner .products .price .new .inner {
    font-size: 23px;
  }
}

@media (max-width: 767.5px) {
  .banner .products .price .new .inner {
    font-size: 18px;
  }
}

@media (max-width: 575.5px) {
  .banner .products .price .new .inner {
    font-size: 11px;
  }
}

.banner .products .price .new .value {
  font-family: "ProximaNova", sans-serif;
  font-weight: 700;
  font-size: 37px;
  line-height: 1.11;
  margin-right: 7px;
}

@media (max-width: 1199.5px) {
  .banner .products .price .new .value {
    font-size: 32px;
  }
}

@media (max-width: 767.5px) {
  .banner .products .price .new .value {
    font-size: 20px;
  }
}

@media (max-width: 575.5px) {
  .banner .products .price .new .value {
    font-size: 15px;
  }
}

.banner .bottom_panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  padding-left: 9px;
}

@media (max-width: 767.5px) {
  .banner .bottom_panel {
    flex-direction: column-reverse;
    padding-left: 0;
  }
}

.banner .buttons {
  display: flex;
}

@media (max-width: 767.5px) {
  .banner .buttons {
    margin-top: 20px;
  }
}

.banner .button-common:first-child {
  margin-right: 42px;
}

@media (max-width: 991.5px) {
  .banner .button-common:first-child {
    margin-right: 20px;
  }
}

@media (max-width: 575.5px) {
  .banner .button-common:first-child {
    margin-right: 10px;
  }
}

.banner .advantages {
  display: flex;
  padding: 5px 18px;
  background: #fff;
  align-items: center;
}

@media (max-width: 575.5px) {
  .banner .advantages {
    padding: 3px 7px;
  }
}

.banner .advantage {
  margin: 0 7px;
}

@media (max-width: 575.5px) {
  .banner .advantage {
    margin: 0 3px;
  }
}

@media (max-width: 575.5px) {
  .banner .advantage img {
    max-width: 53px;
  }
}
