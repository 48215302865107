.banner{
	background-image: url(../img/old/bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	// min-height: calc(100vh - 70px);
	padding-top: 52px;
	padding-bottom: 43px;
	@include lg{
		padding-top: 30px;
		padding-bottom: 30px;
	}
	@include md{
		padding-top: 10px;
	}
	.title-main{
		text-align: center;
	}
	.mapper{
		&_1{
			position: absolute;
			left: 0;
			top: 0;
			// background: red;
			width: 23%;
			height: 80%;
			// opacity: .4;
		}
		&_2{
			position: absolute;
			left: 23%;
			top: 0;
			// background: yellow;
			width: 29%;
			height: 85%;
			// opacity: .4;
			z-index: 1;
		}
		&_3{
			position: absolute;
			left: 50%;
			top: 22%;
			// background: blue;
			width: 31.2%;
			height: 65%;
			// opacity: .4;
			z-index: 2;
		}
		&_4{
			position: absolute;
			left: 67%;
			top: 0;
			// background: green;
			width: 32%;
			height: 65%;
			// opacity: .4;
		}
	}
	.products{
		display: flex;
		margin-top: 155px;
		width: 100%;
		position: relative;
		@include lg{
			margin-top: 130px;
		}
		@include md{
			margin-top: 100px;
		}
		@include xs{
			margin-top: 77px;
		}
		.image{
			width: 100%;
			position: relative;
			img{
				max-width: 100%;
			}
		}
		.name{
			color: #ffffff;
			font-size: 26px;
			font-weight: 900;
			line-height: 0.48;
			text-transform: uppercase;
			@include lg{
				font-size: 25px;
			}
			@include sm{
				font-size: 18px;
			}
			@include xs{
				font-size: 11px;
			}
		}
		.info{
			position: absolute;
			text-align: right;
			&1{
				left: 0;
				top: -97px;
				@include md{
					top: auto;
					bottom: -37px;
				}
				@include xs{
					top: -54px;
				}
			}
			&2{
				left: 27.5%;
				top: -97px;
				@include lg{
					left: 30%;
				}
				@include md{
					top: -80px;
					left: 21%;
				}
				@include xs{
					top: auto;
					bottom: -37px;
					left: 6%;
				}
			}
			&3{
				display: flex;
				align-items: center;
				left: 52.2%;
				bottom: -18px;
				@include xs{
					flex-direction: column;
					bottom: -32px;
					left: 60%;
				}
				.name{
					margin-top: 18px;
				}
				.price{
					margin-left: 30px;
					@include xs{
						margin-left: 15px;
					}
				}
			}
			&4{
				right: 2%;
				top: -97px;
				@include md{
					top: -80px;
				}
				@include xs{
					right: 0;
					top: -60px;
				}
			}
		}
		.price{
			display: flex;
			margin-top: 15px;
			align-items: center;
			.old{
				color: #ffffff;
				line-height: 1.11;
				text-align: center;
				margin-right: 20px;
				margin-top: 9px;
				font-size: 25px;
				position: relative;
				@include lg{
					font-size: 20px;
					margin-right: 10px;
				}
				@include sm{
					font-size: 12px;
				}
				@include xs{
					font-size: 10px;
				}
				.value{
					font-family: $font0;
					font-weight: 300;
					font-size: 28px;
					margin-right: 4px;
					@include lg{
						font-size: 23px;
					}
					@include sm{
						font-size: 16px;
						margin-right: 1px;
					}
					@include xs{
						font-size: 14px;
					}
				}
				&:after{
					content: '';
					position: absolute;
					height: 3px;
					width: 110%;
					background: #ff7d00;
					left: -5%;
					top: 50%;
					transform: rotate(-3deg) translateY(-50%);
					@include sm{
						height: 2px;
					}
				}
			}
			.new{
				color: #ffffff;
				position: relative;
				text-align: center;
				padding: 1.8% 6% 2%;
				min-width: 167px;
				@include lg{
					padding: 1.2% 3%;
					min-width: 150px;
				}
				@include sm{
					min-width: auto;
					padding: 1.2% 7%;
				}
				&::before{
					content: '';
					background-color: #ff7d00;
					position: absolute;
					left: 0;
					width: 100%;
					top: 0;
					height: 100%;
					transform: skewX(-18deg);
				}
				.inner{
					position: relative;
					z-index: 1;
					white-space: nowrap;
					font-weight: 300;
					font-size: 29px;
					font-family: $font1;
					@include lg{
						font-size: 23px;
					}
					@include sm{
						font-size: 18px;
					}
					@include xs{
						font-size: 11px;
					}
				}
				.value{
					font-family: $font0;
					font-weight: 700;
					font-size: 37px;
					line-height: 1.11;	
					margin-right: 7px;
					@include lg{
						font-size: 32px;
					}
					@include sm{
						font-size: 20px;
					}
					@include xs{
						font-size: 15px;
					}
				}
			}
		}
	}
	.bottom_panel{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 60px;
		padding-left: 9px;
		@include sm{
			flex-direction: column-reverse;
			padding-left: 0;
		}
	}
	.buttons{
		display: flex;
		@include sm{
			margin-top: 20px;
		}
	}
	.button-common{
		&:first-child{
			margin-right: 42px;
			@include md{
				margin-right: 20px;
			}
			@include xs{
				margin-right: 10px;
			}
		}
	}
	.advantages{
		display: flex;
		padding: 5px 18px;
		background: #fff;
		align-items: center;
		@include xs{
			padding: 3px 7px;
		}

	}
	.advantage{
		margin: 0 7px;
		@include xs{
			margin: 0 3px;
		}
		img{
			@include xs{
				max-width: 53px;
			}
		}
	}

}