.nobr{
	white-space: nowrap;
}
.title{
	&-main{
		color: #fffefe;
		font-size: 60px;
		font-weight: 400;
		line-height: 1;
		@include lg{
			font-size: 50px;
		}
		@include lg{
			font-size: 40px;
		}
		@include sm{
			font-size: 31px;
		}
		b{
			font-weight: 800;
		}
	}
	&-common{
		color: #b8274e;
		font-size: 60px;
		font-weight: 900;
		line-height: 1;
		text-transform: uppercase;
		@include lg{
			font-size: 50px;
		}
		@include lg{
			font-size: 40px;
		}
		@include sm{
			font-size: 31px;
		}
	}
}
