// font-weight helper:

// 100 Extra Light or Ultra Light;
// 200 Light or Thin; 
// 300 Book or Demi;
// 400 Regular or Normal;
// 500 Medium;
// 600 Semibold or Demibold;
// 700 Bold;
// 800 Black or Extra Bold or Heavy;
// 900 Extra Black or Fat or Ultra Black

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false ) {
	@font-face {
		font-family: $font-family;
		font-weight: $weight;
		font-style: $style;
		font-display: swap;
		src: url('#{$file-path}.woff') format('woff')
	}
}

@include font-face("BebasNeue", "../fonts/BebasNeueLight", 200);
@include font-face("ProximaNova", "../fonts/ProximaNova-Black", 800);
@include font-face("ProximaNova", "../fonts/ProximaNova-Bold", 700);
@include font-face("ProximaNova", "../fonts/ProximaNova-Extrabld", 900);
@include font-face("ProximaNova", "../fonts/ProximaNova-Regular", 400);
@include font-face("TTNorms", "../fonts/TTNorms-Regular", 400);