.button{
	display: inline-block;
	text-align: center;
	cursor: pointer;
	border: none;
	&_wrap{
		text-align: center;
	}
	&-common{
		color: #b7274c;
		font-size: 15px;
		font-weight: 400;
		line-height: 2.13;
		text-transform: uppercase;
		width: 157px;
		height: 45px;
		box-shadow: 3px 1px 7px 1px rgba(2, 3, 2, 0.27);
		background-color: #ffffff;
		border-radius: 23px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: background-color .3s, color .3s;
		@include xs{
			font-size: 14px;
			width: 140px;
		}
		&:hover{
			transition: background-color .3s, color .3s;
			background-color: #ff7d00;
			color: #ffffff;
		}
	}
	&-buy{
		color: #ffffff;
		font-size: 22px;
		font-weight: 400;
		line-height: 2.13;
		text-transform: uppercase;
		box-shadow: 3px 1px 7px 1px rgba(2, 3, 2, 0.27);
		border-radius: 33px;
		background-color: #b7274c;
		width: 192px;
		height: 66px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: background-color .3s, color .3s;
		@include md{
			// font-size: 14px;
			width: 170px;
		}
		&:hover{
			transition: background-color .3s, color .3s;
			background-color: #ff7d00;
			color: #ffffff;
		}
	}
	&-scroll{
		width: 30px;
		height: 21px;
		border: none;
		background: transparent;
		transition: background-image .3s;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='30.5px' height='21.5px'%3E %3Cpath fill-rule='evenodd' stroke='rgb(255, 125, 0)' stroke-width='5px' stroke-linecap='round' stroke-linejoin='round' fill='none' d='M24.801,7.723 L18.004,14.775 C15.796,17.066 12.216,17.066 10.008,14.775 L3.212,7.723 '/%3E %3C/svg%3E");
		&:hover{
			transition: background-image .3s;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='30.5px' height='21.5px'%3E %3Cpath fill-rule='evenodd' stroke='rgb(184, 39, 78)' stroke-width='5px' stroke-linecap='round' stroke-linejoin='round' fill='none' d='M24.801,7.723 L18.004,14.775 C15.796,17.066 12.216,17.066 10.008,14.775 L3.212,7.723 '/%3E %3C/svg%3E");
		}
	}
}